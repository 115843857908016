var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-max-width"},[_c('section-hero',{attrs:{"content":_vm.page.hero}}),_vm._v(" "),(_vm.page.video)?_c('section-video',_vm._b({},'section-video',_vm.page.video,false)):_vm._e(),_vm._v(" "),_vm._l((_vm.page.sections),function(section,index){return _c('div',{key:section.type + index},[(section.type === 'trending_topics')?_c('section-trending-topics',{attrs:{"content":section}}):_vm._e(),_vm._v(" "),(section.type === 'cta')?_c('section-cta',{attrs:{"content":section}}):_vm._e(),_vm._v(" "),(section.type === 'resource_list')?_c('resources-list',{staticClass:"section",attrs:{"params":section.params,"col-count":section.columns || 4,"title":section.title,"subtitle":section.description,"link":section.cta,"is-homepage":true,"amplitude-events":{
        pageType: 'Navigation',
        pagePositionofCard: 'Content',
        pageUrl: _vm.$route.fullPath,
        positionDetail: 'Popular Resources',
        pageName: 'Home',
      }}}):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }