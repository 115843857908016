<template>
  <div class="page-max-width">
    <section-hero :content="page.hero" />
    <section-video v-if="page.video" v-bind="page.video" />
    <div v-for="(section, index) in page.sections" :key="section.type + index">
      <section-trending-topics v-if="section.type === 'trending_topics'" :content="section" />
      <section-cta v-if="section.type === 'cta'" :content="section" />
      <resources-list
        v-if="section.type === 'resource_list'"
        class="section"
        :params="section.params"
        :col-count="section.columns || 4"
        :title="section.title"
        :subtitle="section.description"
        :link="section.cta"
        :is-homepage="true"
        :amplitude-events="{
          pageType: 'Navigation',
          pagePositionofCard: 'Content',
          pageUrl: $route.fullPath,
          positionDetail: 'Popular Resources',
          pageName: 'Home',
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SectionHero from '~/components/homepage/SectionHero.vue';
import SectionTrendingTopics from '~/components/homepage/SectionTrendingTopics.vue';
import SectionCta from '~/components/homepage/SectionCta.vue';
import SectionVideo from '~/components/homepage/SectionVideo.vue';
import ResourcesList from '~/components/resource/ResourcesList.vue';
import { organization } from '~/utils/schema-org';

export default {
  name: 'Homepage',
  components: {
    SectionHero,
    SectionTrendingTopics,
    SectionCta,
    SectionVideo,
    ResourcesList,
  },
  middleware({ store, localePath, redirect }) {
    if (store.getters?.loggedInUser) {
      redirect(localePath('/hub/'));
    }
  },
  async asyncData({ app }) {
    const data = { page: null, location: app.i18n.locale };

    try {
      // Load page content from a JSON file - only works with asyncData, not fetch
      const page = await import('@/data/homepage.json');

      if (page && page[app.i18n.locale]) {
        data.page = page[app.i18n.locale];
        data.title = data.page.title;
        data.description = data.page.description;
      }
    } catch (err) {
      //
    }

    return data;
  },
  data() {
    return {
      title: 'Teaching & educational resources',
      description:
        'Teach Starter offers a range of time-saving, educational teaching resources for elementary school teachers. Download unit and lesson plans, educational posters, classroom games and activities, worksheets and more!',
      isHomepage: true,
    };
  },
  jsonld() {
    return organization;
  },
  head() {
    return {
      title: this.title,
      meta: [
        { hid: 'title', name: 'title', content: this.title },
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
      ],
      link: this.$hrefLangsPage(this.$route, '/'),
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  mounted() {
    this.$amplitudeEventPageViewed('Homepage', this.$route.path, 'Home');
  },
};
</script>

<style scoped lang="scss">
.columns {
  padding: 0;
}

.section {
  margin: 0 $base-padding * 2 $base-padding-two-thirds * 5 $base-padding * 2;
  padding: $base-padding-two-thirds * 5 $base-padding * 2;
  &.section-resources-list {
    padding: $base-padding-two-thirds * 5 $base-padding * 2 0 $base-padding * 2;
  }
}

@media #{$medium-up} {
  .section {
    padding: 0 5rem;
    margin: 5rem 5rem 0 5rem;
  }
}

:deep(.button.button-outlined) {
  background: none;
  border: 0;
  color: $link-blue-500;
  border-radius: 0;
  box-shadow: none;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $grey-100;
  }
}

@media #{$small} {
  :deep(.section-resources-list div.columns ul.row li:nth-child(n + 7)) {
    display: none !important;
  }
}

@media #{$small-only} {
  :deep(.section-resources-list div.columns ul.row li:nth-child(n + 4)) {
    display: none !important;
  }
}
</style>
