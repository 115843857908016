<template>
  <div class="wistia-video">
    <template v-if="embed === 'iframe'">
      <div class="wistia_responsive_padding" style="padding: 56.25% 0 0 0; position: relative">
        <div
          class="wistia_responsive_wrapper"
          style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
        >
          <iframe
            :src="`https://fast.wistia.net/embed/iframe/${encodeURIComponent(
              wistiaId
            )}?videoFoam=true`"
            allow="encrypted-media;"
            class="wistia_embed"
            name="wistia_embed"
            :title="title"
            width="100%"
            height="100%"
            allowfullscreen
            webkitallowfullscreen
            msallowfullscreen
          ></iframe>
        </div>
      </div>
    </template>
    <template v-else-if="embed === 'inline'">
      <div class="wistia_responsive_padding" style="padding: 56.25% 0 0 0; position: relative">
        <div
          class="wistia_responsive_wrapper"
          style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
        >
          <div
            :class="`wistia_embed wistia_async_${wistiaId} videoFoam=true`"
            style="height: 100%; position: relative; width: 100%"
          >
            <div
              class="wistia_swatch"
              style="
                height: 100%;
                left: 0;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                top: 0;
                transition: opacity 200ms;
                width: 100%;
              "
            >
              <img
                :src="
                  'https://fast.wistia.com/embed/medias/' + encodeURIComponent(wistiaId) + '/swatch'
                "
                style="filter: blur(5px); height: 100%; object-fit: contain; width: 100%"
                :alt="title"
                aria-hidden="true"
                onload="this.parentNode.style.opacity=1;"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 *
 * A video player that plays a video from a Wistia account.
 *
 * Note: The inline version of this component uses the recommended embed code from Wistia which is both
 * responsive and exposes SEO Metadata.
 *
 */
export default {
  name: 'WistiaVideo',
  props: {
    /**
     * The title of the video.
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * The Wistia ID of the video
     */
    wistiaId: {
      type: String,
      required: true,
    },
    /**
     * The type of embedding to use (inline or iframe)
     * @default
     */
    embed: {
      type: String,
      required: false,
      default: 'iframe',
    },
  },
  mounted() {
    if (this.embed === 'inline') {
      const assetsScript = document.createElement('script');
      assetsScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      assetsScript.async = true;
      const embedScript = document.createElement('script');
      embedScript.src = `https://fast.wistia.com/embed/medias/${this.wistiaId}.jsonp`;
      assetsScript.async = true;
      this.$el.prepend(assetsScript);
      this.$el.prepend(embedScript);
    }
  },
};
</script>
